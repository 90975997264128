





































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { formatTimestamp, getPassedTime } from '@/utils/utils'
import { Form } from 'element-ui'

@Component({ name: 'projectTemplate' })
export default class projectTemplate extends VueBase {
  private addDialogOpen = false
  private page = 1
  private allTotal = 0
  private isAdd = true
  private options = []
  private wordOptions = []
  private severList = [
    {
      word: 'Herder',
      condition: this.$t('menu.Form.existenceKey'),
      fit: '',
      isEnable: false,
    },
  ]
  private policyList = []
  private keyword = ''
  private form = {
    name: '',
    region: '',
    value: true,
  }
  private addfiled() {
    this.severList.push({
      word: 'Herder',
      condition: this.$t('menu.Form.existenceKey'),
      fit: '',
      isEnable: false,
    })
  }
  private delfiled(index: any) {
    this.severList = this.severList.filter((item, key) => {
      return index !== key
    })
  }
  private newSelectData() {
    this.page = 1
    this.getTableData()
  }
  private rules = {
    template_name: [
      {
        required: true,
        message: this.$t('menu.Form.namePlaceholder'),
        trigger: 'blur',
      },
    ],
    scanPolicy: [
      {
        required: true,
        message: this.$t('menu.Form.namePlaceholder'),
        trigger: 'blur',
      },
    ],

    proactiveVerification: [
      {
        required: true,
        message: this.$t('menu.Form.proactiveVerificationPlaceholder'),
        trigger: 'change',
      },
    ],
  }
  formatTimestamp(time: number) {
    if (time) {
      return formatTimestamp(time)
    } else {
      return ''
    }
  }
  private async resetPwd(item: any) {
    this.$confirm(
      this.$t('views.userList.resetConfirm') as string,
      this.$t('views.userList.resetConfirmPop') as string,
      {
        confirmButtonText: this.$t('views.userList.submit') as string,
        cancelButtonText: this.$t('views.userList.cancel') as string,
        type: 'warning',
      }
    ).then(async () => {
      const { status, msg } = await this.services.user.reset({
        userId: item.id as number,
      })
      if (status === 201) {
        this.$message({
          type: 'success',
          message: msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
      }
    })
  }

  private async delTemplate(id: number) {
    this.$confirm(
      this.$t('views.userList.deleteConfirm') as string,
      this.$t('views.userList.deleteConfirmPop') as string,
      {
        confirmButtonText: this.$t('views.userList.submit') as string,
        cancelButtonText: this.$t('views.userList.cancel') as string,
        type: 'warning',
      }
    ).then(async () => {
      this.loadingStart()
      const { status, msg } = await this.services.setting.delProjecttemplat({
        id,
      })
      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      this.$message({
        type: 'success',
        message: msg,
        showClose: true,
      })
      await this.getTableData()
    })
  }
  private validateCheckPass(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('views.userList.rePWD') as string))
    } else if (value !== this.configForm.password) {
      callback(new Error(this.$t('views.userList.diffPWD') as string))
    } else {
      callback()
    }
  }
  private handleCurrentChange(val: number) {
    this.page = val
    this.getTableData()
  }
  private tableData = []
  async getTableData() {
    this.loadingStart()
    const res = await this.services.setting.listProjecttemplat({
      page: this.page,
      page_size: 10,
    })
    this.loadingDone()
    if (res.status === 201) {
      this.tableData = res.data
      this.allTotal = res.page.alltotal
      return
    }
    this.$message.error(res.msg)
  }

  private configForm: any = {
    template_name: '',
    scanPolicy: '',
    proactiveVerification: '',
  }

  private addDialogShow() {
    this.configForm = {
      template_name: '',
      scanPolicy: '',
      proactiveVerification: '',
    }
    this.severList = [
      {
        word: 'Herder',
        condition: this.$t('menu.Form.existenceKey'),
        fit: '',
        isEnable: false,
      },
    ]
    this.addDialogOpen = true
  }

  private async editTemplate(row: any) {
    this.isAdd = false
    const res = await this.services.setting.getProjecttemplat({ id: row.id })
    console.log(res)
    this.configForm = {
      template_name: row.template_name,
      scanPolicy: row.scan_id,
      proactiveVerification: row.vul_validation,
      uid: row.id,
    }
    this.addDialogOpen = true
  }
  private cancelAdd() {
    this.addDialogOpen = false
    if (this.isAdd === false) {
      this.isAdd = true
    }
  }
  private creatProjecttemplat() {
    console.log(this.configForm.department)
    ;(this.$refs.ruleForm as Form).validate(async (valid: any) => {
      if (valid) {
        const params: any = {
          template_name: this.configForm.template_name,
          scan_id: this.configForm.scanPolicy,
          vul_validation: this.configForm.proactiveVerification,
        }
        if (this.configForm.uid) {
          params.id = this.configForm.uid
        }
        console.log(params)
        this.loadingStart()
        if (this.isAdd) {
          const { status, msg } =
            await this.services.setting.creatProjecttemplat(params)
          this.loadingDone()
          if (status !== 201) {
            this.$message({
              type: 'error',
              message: msg,
              showClose: true,
            })
            return
          }
          this.$message.success(msg)
        } else {
          const { status, msg } = await this.services.setting.putProjecttemplat(
            params
          )
          this.loadingDone()
          if (status !== 201) {
            this.$message({
              type: 'error',
              message: msg,
              showClose: true,
            })
            return
          }
          this.$message.success(msg)
        }
        this.addDialogOpen = false
        this.getTableData()
        this.isAdd = true
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
  fmtOptions(options: Array<any>) {
    options.forEach((item: any) => {
      if (item.id < 0) {
        item.disabled = true
      }
      item.value = { name: item.label, id: item.id }
      if (item.children.length) {
        this.fmtOptions(item.children)
      } else {
        delete item.children
      }
    })
  }

  private async getPolicy() {
    const { status, msg, data } = await this.services.setting.strategyUserList()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.policyList = data
  }
  created() {
    this.getPolicy()
    this.getTableData()
  }
}
